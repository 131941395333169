import { useState } from "react";
import Massage from '../assets/massage.jpg';
import Pedicure from '../assets/pedicure.jpg';
import Facial from '../assets/facial.jpg';

// The Following images are from Pixabay : 
// https://pixabay.com/photos/woman-wellness-spa-relaxation-6557552/
// https://pixabay.com/photos/feet-toes-pedicure-nails-toenails-5893684/
// https://pixabay.com/photos/woman-hands-massage-massaging-567021/


const Packages = () => {



const [counter,setCounter] = useState(0);

const PkgMassage = () => {
    setCounter(counter + 200 + 26 );
}

const PkgPedicure = () => {
    setCounter(counter + 150 + 19);
}

const PkgFacial = () => {
    setCounter(counter + 120 + 15);
}

// The tax is close, but when I added the decimal value, the code did some strange things.

    return(
        <div className="packages">
            <h2 className="packages-title text-muted fs-1 mt-5 fw-bolder">Our Packages</h2>
            <p className="fw-bold fs-4">Please choose the following package&#40;s&#41; you desire.</p>
            <p className="tax-info fw-light fs-6">&#40;Tax Included&#41;</p>
            <div className="packages-container d-flex flex-wrap justify-content-center mt-5 mb-5">


            <div className="thumb-img">
                <h3 className="thumb-title text-muted">Massage</h3>
            <img src={Massage} className="img-thumbnail img-fluid me-3" onClick={PkgMassage} alt=" A woman receiving a massage" />
            <p className="fs-3 text-bold">$200</p>
            </div>
            <div className="thumb-img">
            <h3 className="thumb-title text-muted">Pedicure</h3>
            <img src={Pedicure} className="img-thumbnail img-fluid me-3" onClick={PkgPedicure} alt=" A woman receiving a pedicure" />
            <p className="fs-3 text-bold">$150</p>
            </div>
            <div className="thumb-img">
            <h3 className="thumb-title text-muted">Facial</h3>
            <img src={Facial} className="img-thumbnail img-fluid me-3" onClick={PkgFacial} alt=" A woman receiving a facial" />
            <p className="fs-3 text-bold">$120</p>
            </div>
            </div>
            <div className="total-container">
                <h3 className="total-title fs-3 text-muted">Your Total:</h3>
                <p className="counter fs-3 mb-5">${counter}</p>
            </div>
        </div>
    );
}
export default Packages;