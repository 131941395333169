import { useState } from "react";
import styled from 'styled-components';


const Circle = styled. div`
height: 100%
width: 100%;
background-color: #a30a0a;
&:hover {
    background-color: #8985ff;
    transition: ease-in 250ms
  }
`;
const Circle2 = styled. div`
height: 100%
width: 100%;
background-color: #ba0693;
&:hover {
    background-color: #242fff;
    transition: ease-in 250ms
  }
`;
const Circle3 = styled. div`
height: 100%
width: 100%;
background-color: #1aab89;
&:hover {
    background-color: #54ff0a;
    transition: ease-in 250ms
  }
`;
const Circle4 = styled. div`
height: 100%
width: 100%;
background-color: #a4ab1a;
&:hover {
    background-color: #ffd500;
    transition: ease-in 250ms
  }
`;



const Products = () => {

const [count, setCount] = useState(0);
const [total, setTotal] = useState(0)

const ProdMani = () => {

    setCount(count + 300);
    setTotal(count + 300 + 39 * 1);
}

const ProdMeditation = () => {

    setCount(count + 800);
    setTotal(count + 800 + 104 * 1);
}
    

const ProdFaceCream = () => {
    
    setCount(count + 150);
    setTotal(count + 150 + 20 * 1);
}
    

const ProdFootScrub = () => {
    
    setCount(count + 90);
    setTotal(count + 90 + 12 * 1);
}
    
    // I tried calculating the total tax so it can show up on the total, but it was not working properly for me. This one was kind of tough.
 
    return(
        <div className="products">
            <h2 className="products-title text-muted fs-1 mt-5 fw-bolder">Our Products</h2>
            <p className="fw-bold fs-4">Please choose one of our following product&#40;s&#41; that you desire.</p>
            <p className="fs-6 fw-light">Calculate your total below.</p>

            <div className="products-container d-flex flex-wrap justify-content-center mt-5 mb-5">
            <div className="divider ps-5 mb-3">
            <Circle className="p-5 rounded-circle mb-4 product-name" onClick={ProdMani}><p className=" fs-6 fw-bold text-white"> Zen Manicure/Facial</p>
            <p className=" fs-6 text-white fw-bold"> $300</p></Circle>
            <Circle2 className="p-5 rounded-circle product-name" onClick={ProdMeditation}><p className=" fs-6 fw-bold text-white"> Zen Meditation/ Massage</p>
            <p className=" fs-6 text-white fw-bold"> $800</p></Circle2>
            </div>
            <div className="divider ps-5">
            <Circle3 className="p-5 rounded-circle mb-4 product-name" onClick={ProdFaceCream}><p className=" fs-6 fw-bold text-white"> Zen Face Cream & Soap</p>
            <p className=" fs-6 fw-bold text-white fw-bold"> $150</p></Circle3>
            <Circle4 className="p-5 rounded-circle product-name" onClick={ProdFootScrub}><p className=" fs-6 fw-bold text-white"> Zen Foot Scrub</p>
            <p className=" fs-6 fw-bold text-white fw-bold"> $90</p></Circle4>
            </div>
            </div>
            <div className="total-container ms-5">
                <h3 className="total-title fs-3 text-muted mt-4">Sub Total:</h3>
                <p className="counter fs-3 mb-5">${count}</p>
            </div>
            <div className="total-container ms-5">
                <h3 className="total-title fs-3 text-muted">Total:</h3>
                <p className="counter fs-3 mb-5">${total}</p>
            </div>
        </div>
    );
}

export default Products;