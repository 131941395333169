import * as React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/zen-stones_logo.png'

const Header = () => {
    return(
<header className="header">
<nav className="navbar navbar-expand-lg">
  <div className="p-3">
    <Link to="/"><img src={Logo} className="img-fluid"></img></Link>
    <Link className="navbar-brand ps-4 fs-2 text-white" to="/">My Zen Spa</Link>
  </div>
  <div className="navbar-nav ms-3">
    <Link className="nav-link fw-light text-dark pe-5" to="/">Home</Link>
    <Link className="nav-link fw-light text-dark pe-5" to="/Packages">Packages</Link>
    <Link className="nav-link fw-light text-dark pe-5" to="/Products">Products</Link>
    <Link className="nav-link fw-light text-dark pe-5" to="/Book_Appointment">Book Appointment</Link>
  </div>
</nav>
</header>
    );
}

export default Header;