import styled from 'styled-components';


const Footer =styled.footer`
background-color: #edf4ff;
color: #000;
width:100%;
font-weight: 100;
height: 3rem;
padding: 3rem;

`;

export default Footer;