import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';


// Pages that will be imported

import Home from './pages/Home';
import Packages from './pages/Packages';
import Products from './pages/Products';
import Book_Appointment from './pages/Book_Appointment';

import './App.css';





function App() {
  return (
    <div className="app">
     <div className="mb-0">
      <Header />
     </div>
     <div className="main">
      <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Packages" element={<Packages />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Book_Appointment" element={<Book_Appointment />} />
            
      </Routes>
     </div>
     <Footer>
            <footer className="container-fluid">
<small className="footer-info">Copyright &copy; My Zen 2024, All Rights Reserved</small>
            </footer>
            </Footer>
    </div>
  );
}

export default App;
