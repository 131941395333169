import * as React from 'react';
import styled from 'styled-components';

const Form = styled.div` 
    width: 500px;
    height: 500px;
    padding: 2em;
    margin: 0 auto;
    margin-bottom:3em;
    background-color: #ceede8;
`;



class BookAppointments extends React.Component {
    constructor() {
        super()
        this.state = {
            name: '',
            phone: '',
            message: '',
        }
    }



handleNameFieldChange = (event) => {
this.setState({

    name: event.target.value

});
}

handlePhoneFieldChange = (event) => {
    this.setState({
        phone: event.target.value
    });
}

handleMessageFieldChange = (event) => {
    this.setState({
        message: event.target.value
    });
}


handleSubmit = () => {
    const {name, message, phone} = this.state;
    console.log({
        name, 
        message,
        phone
    });

}





render() {
const {name, message, phone} = this.state;

    return(
        <div className="book">
        <h2 className="packages-title text-muted fs-1 mt-5 fw-bolder">Book Today!</h2>
        <p className="fw-bold fs-4">Schedule your spa appointment today</p>
       <Form>
<div className="form-group text-start p-2">
    <label htmlFor="name">Name:</label>
    <input
    type="text"
    id="name"
    className="form-control text-muted"
    value={name}
    onChange={(event)=> this.handleNameFieldChange(event)}
    />
</div>

<div className="form-group text-start p-2">
    <label htmlFor="service">Type Of service:</label>
    <select
    type="text"
    id="service"
    className="form-control text-muted">
<option value="">Please select a service ---</option> 
<option value="massage">Massage</option>
<option value="pedicure">Pedicure</option>
<option value="manicure">Manicure</option>
<option value="facial">Facial</option>
    </select>
</div>

<div className="form-group text-start p-2">
    <label htmlFor="phone">Phone Number:</label>
    <input
    type="text"
    id="phone"
    className="form-control text-muted"
    value={phone}
    onChange={(event)=> this.handlePhoneFieldChange(event)}
    />
</div>

<div className="form-group text-start p-2">
    <label htmlFor="message">Your Message:</label>
    <textarea
    type="text"
    id="message"
    className="form-control text-muted"
    value={message}
    onChange={(event)=> this.handleMessageFieldChange(event)}
    >
    </textarea>
</div>
<div className="form-group">
    <button className="btn btn-success mt-3" onClick={this.handleSubmit}>Submit</button>
</div>
       </Form>
        </div>
  )};

}
// I tried to turn the dropdown into an object, but it was becoming difficult.

export default BookAppointments;