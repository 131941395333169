import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Hero from '../assets/stones.jpg';
import Logo from '../assets/zen-stones_logo.png'

const HeroText = styled.p`
font-size: 1.4rem;
font-weight:200;
padding-top: 2em;
margin: 2.8em;

`;

const BookButton = styled.button`
background-color: #169c54;
color: #fff;
font-size:1.2rem;

&:hover{
    background-color: #bd022d;
    color: #fff; 
    transition: ease-in-out 500ms;
}

`;

const MyProducts = styled.button`
background-color:#141414;
font-size:1.2rem;

&:hover{
    background-color: #4d3f43; 
    transition: ease-in-out 500ms;
}


`;



// Icon is from Flaticon
// https://www.flaticon.com/free-icon/zen-stones_3041183
// <a href="https://www.flaticon.com/free-icons/zen" title="zen icons">Zen icons created by surang - Flaticon</a>





// I was going to try and do props with the buttons, but wasn't able to figure it out a bit. I just decided to make separate styling instead.

const Home = () => {

    return(
        <div className="hero-section">
         <div className="hero-image">
         </div>
    <img src={Hero} className="col-12" alt="Stones laying in grass" />
    <div className="hero-title-container">
        <h2 className="hero-title fs-1 text-muted pt-5">Making Zen your way!</h2>
    </div>
    <div className="hero-text">
        <HeroText>
        <p className="hero-text-container text-muted"> Here at My Zen Spa, we make sure that all our patrons are relaxed and taken care of by the highest power. You'll be sure to receive the full benefits of our services and products. For the last 20 years, our spa only uses organic ingredients and materials. Everyone at My Zen Spa believes in good physical, mental, and spiritual health!</p>
        </HeroText>
    </div>
    <div className="btn-container">
        <BookButton className="btn book-btn me-4 my-5"><Link className="link-light text-decoration-none" to="/Book_Appointment">Book A Message Today!</Link></BookButton>
        <MyProducts className="btn product-btn my-5 "><Link className="link-light text-decoration-none" to="/Products">Buy Our Product</Link></MyProducts>
   </div>
        <div className="small-text mb-5 mt-5 fs-6 fw-light">
            <img src={Logo} />
            <p className="zen-text">Have Zen your way!</p>
        </div>
    
    </div>
        
    );
}



export default Home;